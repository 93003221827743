.DropZoneRoot {
  border: 1px dashed #212529;
  border-radius: 0.375rem;
  box-sizing: border-box;
  padding: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  & > div > img {
    max-height: 300px;
    @media only screen and (min-width: 768px) and (max-width: 1240px) { max-height: 150px }
    @media only screen and (max-width: 767px) { max-height: 100px }
  }
}

.DropZoneRootError {
  border: 1px dashed red;
  color: red;
  border-radius: 0.375rem;
  box-sizing: border-box;
  padding: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  & > div > img {
    max-height: 300px;
    @media only screen and (min-width: 768px) and (max-width: 1240px) { max-height: 150px }
    @media only screen and (max-width: 767px) { max-height: 100px }
  }
}