.AdminPageWrapperRoot {
  box-sizing: border-box;
  padding: 80px 40px 40px 40px;
  position: relative;

  @media only screen and (min-width: 768px) and (max-width: 1240px) {
    padding: 80px 20px 20px 20px !important;
  }

  @media only screen and (max-width: 767px) {
    padding: 50px 10px 10px 10px !important;
  }
}

.PageWrapperHeader {
  width: 100%;
}

.PageWrapperContent {
  display: flex;
  column-gap: 20px;

  @media only screen and (max-width: 767px) {
    flex-direction: column;
    row-gap: 10px;
  }
}

.PageWrapperNavigation {
  min-width: 300px;
  width: 300px;
  height: 510px;
  border-radius: 0.375rem;
  box-sizing: border-box;
  padding: 20px 0;
  border: 1px solid #212529;

  @media only screen and (min-width: 768px) and (max-width: 1240px) {
    min-width: 200px !important;
    width: 200px !important;
    padding: 10px 0 !important;
  }

  @media only screen and (max-width: 767px) {
    min-width: 100% !important;
    width: 100% !important;
    height: auto !important;
  }
}

.PageWrapperChildren {
  position: relative;
  flex: auto;
  border-radius: 0.375rem;
  box-sizing: border-box;
  padding: 20px;
  border: 1px solid #212529;

  @media only screen and (max-width: 767px) {
    padding: 12px !important;
  }
}

.PageWrapperNavigationLink {
  display: block;
  width: 100%;
  box-sizing: border-box;
  padding: 10px 20px;
  background: transparent;
  color: #212529;
  text-decoration: none;
  transition: all .2s;

  &:hover {
    color: white;
    background: #212529;
  }

  @media only screen and (min-width: 768px) and (max-width: 1240px) {
    font-size: .8rem !important;
  }

  @media only screen and (max-width: 767px) {
    font-size: .8rem !important;
  }
}

.PageWrapperNavigationLink.active {
  display: block !important;
  width: 100% !important;
  box-sizing: border-box !important;
  padding: 10px 20px !important;
  color: white !important;
  background: #212529 !important;
  text-decoration: none !important;
}

.PageWrapperLogout {
  position: absolute;
  top: 20px;
  right: 40px;

  @media only screen and (min-width: 768px) and (max-width: 1240px) {
    top: 20px !important;
    right: 20px !important;
  }

  @media only screen and (max-width: 767px) {
    top: 10px !important;
    right: 10px !important;
  }
}

.PageWrapperVersion {
  position: absolute;
  top: 20px;
  left: 40px;

  @media only screen and (min-width: 768px) and (max-width: 1240px) {
    top: 20px !important;
    left: 20px !important;
    font-size: .8rem;
  }

  @media only screen and (max-width: 767px) {
    top: 10px !important;
    left: 10px !important;
    font-size: .8rem;
  }
}