.activeChatRoot {
  height: 100%;
  position: relative;
  box-sizing: border-box;
  padding: 10px 10px 60px 10px !important;

  @media only screen and (max-width: 979px) {
    min-height: 500px;
  }

  &NewMessage {
    position: absolute;
    width: 100%;
    background: #dee2e6;
    bottom: 0;
    left: 0;
    box-sizing: border-box;
    padding: 10px;
    column-gap: 10px;
  }

  &Messages {
    height: 100%;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 3px;
      position: absolute;
      background: transparent;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background: #888;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    &Wrapper {
      display: flex;
      width: 100%;
      flex: auto;
    }

    &Item {
      border: 1px solid #212529;
      box-sizing: border-box;
      padding: 10px 10px 20px 10px;
      width: 40%;
      border-radius: 0.375rem;
      margin-bottom: 10px;
      position: relative;

      & > span {
        position: absolute;
        right: 5px;
        bottom: 5px;
        font-size: 12px;
        opacity: .4;
      }
    }
  }
}