.Root {
  max-width: 600px;
  margin: 0 auto;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mainPageContainer {
  @media only screen and (max-width: 767px) {
    width: 100% !important;
    max-width: 100% !important;
  }
  @media only screen and (min-width: 768px) and (max-width: 1240px) {
    width: 100% !important;
    max-width: 100% !important;
  }
}