// helpcss: marginTopClass
.marginTop10 {
  margin-top: 10px;
}
.marginTop20 {
  margin-top: 20px;
}
.marginTop40 {
  margin-top: 40px;
}
.marginTop60 {
  margin-top: 60px;
}

// helpcss: marginBottomClass
.marginBottom10 {
  margin-bottom: 10px;
}
.marginBottom20 {
  margin-bottom: 20px;
}
.marginBottom40 {
  margin-bottom: 40px;
}
.marginBottom60 {
  margin-bottom: 60px;
}

// helpcss: marginRightClass
.marginRight10 {
  margin-right: 10px;
}
.marginRight20 {
  margin-right: 20px;
}
.marginRight40 {
  margin-right: 40px;
}
.marginRight60 {
  margin-right: 60px;
}

// helpcss: marginLeftClass
.marginLeft10 {
  margin-left: 10px;
}
.marginLeft20 {
  margin-left: 20px;
}
.marginLeft40 {
  margin-left: 40px;
}
.marginLeft60 {
  margin-left: 60px;
}

// helpcss: flexbox
.flex {
  display: flex;
}

.flexWrap {
  flex-wrap: wrap;
}

.flex-center {
  display: flex;
  justify-content: center;
}

.flex-column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.flex-space-between {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  column-gap: 12px;
}

// helpcss: grids
.grid2x {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  box-sizing: border-box;
}
.grid3x {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  box-sizing: border-box;
}
.grid4x {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  box-sizing: border-box;
}

.grid5x {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 8px;
  grid-row-gap: 8px;
}
.grid6x {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 6px;
  grid-row-gap: 6px;
}
.grid7x {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-column-gap: 6px;
  grid-row-gap: 6px;
}
.grid8x {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-column-gap: 6px;
  grid-row-gap: 6px;
}


.DatePikerWrapper {
  width: 100%;
}

.DatePikerInput {
  width: 100%;
  display: flex;
  flex: auto;
  border-radius: 0 0.375rem 0.375rem 0;
  box-sizing: border-box;
  padding: 0 0 0 12px;
  height: 38px;
  border: 1px solid #dee2e6;
}


h1 {
  @media only screen and (min-width: 1241px) { font-size: 2.5rem }
  @media only screen and (min-width: 768px) and (max-width: 1240px) { font-size: 1.8rem }
  @media only screen and (max-width: 767px) {font-size: 1.2rem}
}

h5, h4 {
  @media only screen and (min-width: 768px) and (max-width: 1240px) { font-size: 1rem }
  @media only screen and (max-width: 767px) {font-size: 1rem}
}

.tableWrapper, .scrollWrapper {
  @media only screen and (max-width: 500px) {
    overflow-x: scroll;
  }
}

.tableWrapperForBigTable {
  overflow-x: scroll;
}

th, td {
  @media only screen and (min-width: 768px) and (max-width: 1240px) { font-size: .8rem }
  @media only screen and (max-width: 767px) { font-size: .6rem }
}

.page-item, .page-item.active {
  @media only screen and (min-width: 768px) and (max-width: 1240px) { font-size: .8rem }
  @media only screen and (max-width: 767px) { font-size: .6rem }

  & > a, & > span {
    @media only screen and (min-width: 768px) and (max-width: 1240px) { font-size: .8rem }
    @media only screen and (max-width: 767px) { font-size: .6rem }
  }
}

p, b, label {
  @media only screen and (max-width: 767px) {font-size: .8rem}
}

input {
  @media only screen and (min-width: 768px) and (max-width: 1240px) { font-size: .8rem !important;}
  @media only screen and (max-width: 767px) {  font-size: .8rem !important;}
}
select {
  @media only screen and (min-width: 768px) and (max-width: 1240px) { font-size: .8rem !important;}
  @media only screen and (max-width: 767px) {  font-size: .8rem !important;}
}

button {
  @media only screen and (min-width: 768px) and (max-width: 1240px) { font-size: .8rem !important;}
  @media only screen and (max-width: 767px) {  font-size: .8rem !important;}
}

.block {
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
  border: 1px solid #212529;
  border-radius: 0.375rem;
  @media only screen and (min-width: 768px) and (max-width: 1240px) { padding: 12px }
  @media only screen and (max-width: 767px) { padding: 12px }
}

.card-title {
  @media only screen and (min-width: 768px) and (max-width: 1240px) { font-size: 1.1rem !important;}
  @media only screen and (max-width: 767px) {  font-size: .9rem !important;}
}

.card-text {
  @media only screen and (min-width: 768px) and (max-width: 1240px) { font-size: 1rem !important;}
  @media only screen and (max-width: 767px) {  font-size: .8rem !important;}
}

.card-body {
  @media only screen and (min-width: 768px) and (max-width: 1240px) { padding: 16px !important;}
  @media only screen and (max-width: 767px) { padding: 10px !important;}
}

.input-group-text {
  @media only screen and (min-width: 768px) and (max-width: 1240px) { font-size: .8rem !important;}
  @media only screen and (max-width: 767px) {  font-size: .8rem !important;}
  @media only screen and (max-width: 666px) { display: none !important;}
}
.alternative_label {
  display: none;
  @media only screen and (max-width: 666px) { display: block !important; width: 100%}
}

.grid2x {
  @media only screen and (min-width: 768px) and (max-width: 1240px) { grid-template-columns: repeat(1, 1fr)}
  @media only screen and (max-width: 767px) { grid-template-columns: repeat(1, 1fr) }
}
.grid2xCustom {
  @media only screen and (max-width: 1441px) { grid-template-columns: repeat(1, 1fr) }
}

.grid5x {
  @media only screen and (min-width: 768px) and (max-width: 1240px) { grid-template-columns: repeat(3, 1fr)}
  @media only screen and (max-width: 767px) { grid-template-columns: repeat(2, 1fr) }
}

.grid5x_couriers {
  @media only screen and (min-width: 1600px) { grid-template-columns: repeat(5, 1fr) }
  @media only screen and (min-width: 1350px) and (max-width: 1599px) { grid-template-columns: repeat(4, 1fr)}
  @media only screen and (min-width: 1000px) and (max-width: 1350px) { grid-template-columns: repeat(3, 1fr)}
  @media only screen and (min-width: 768px) and (max-width: 1000px) { grid-template-columns: repeat(2, 1fr)}
  @media only screen and (max-width: 767px) { grid-template-columns: repeat(1, 1fr) }
}

.grid6x {
  @media only screen and (min-width: 1800px) and (max-width: 1900px) { grid-template-columns: repeat(5, 1fr)}
  @media only screen and (min-width: 1500px) and (max-width: 1800px) { grid-template-columns: repeat(4, 1fr)}
  @media only screen and (min-width: 1240px) and (max-width: 1500px) { grid-template-columns: repeat(3, 1fr)}
  @media only screen and (min-width: 1000px) and (max-width: 1240px) { grid-template-columns: repeat(3, 1fr)}
  @media only screen and (max-width: 1000px) { grid-template-columns: repeat(2, 1fr) }
}

.badge {
  @media only screen and (max-width: 767px) { word-break: break-all }
}

.grid3x, .grid4x {
  @media only screen and (max-width: 767px) { grid-template-columns: repeat(2, 1fr) }
}
.grid3xTo1Mobile {
  @media only screen and (max-width: 767px) { grid-template-columns: repeat(1, 1fr) }
}
.grid3xTo1Tablet {
  @media only screen and (min-width: 768px) and (max-width: 1240px) { grid-template-columns: repeat(1, 1fr)}
  @media only screen and (max-width: 767px) { grid-template-columns: repeat(1, 1fr) }
}

.marginBottom40, .marginBottom60 {
  @media only screen and (min-width: 768px) and (max-width: 1240px) { margin-bottom: 30px }
  @media only screen and (max-width: 767px) { margin-bottom: 20px }
}
.marginRight20, .marginRight40, .marginRight60 {
  @media only screen and (min-width: 768px) and (max-width: 1240px) { margin-right: 10px }
  @media only screen and (max-width: 767px) { margin-right: 10px }
}
.marginLeft20, .marginLeft40, .marginLeft60 {
  @media only screen and (max-width: 767px) { margin-left: 10px }
}

.modal-dialog {
  @media only screen and (min-width: 768px) and (max-width: 1240px) {
    height: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media only screen and (max-width: 767px) {
    height: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}