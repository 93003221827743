.GlobalLoader {
  &Root {
    width: 100vw;
    height: 100vh;
    backdrop-filter: blur(8px);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 696969;
    transition: all .2s;
  }

  &Block {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &Text {
    margin-top: 20px;
    user-select: none;
  }
}